export class GlobalDataController {

  static _isHomePage(): boolean {
    const homePage = /\/?home/i;
    const { href } = window.location;
    return homePage.test(href);
  }

  static getEnvironment(): string {
    const hostname = window.location.hostname;
    return /^.*\.stage\.aa\.com$/.test(hostname) ? 'stage' :
      /^.*\.qa\.aa\.com$/.test(hostname) || hostname === 'localhost' ? 'qa' : 'prod';
  }
}
